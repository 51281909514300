<template>
  <div :class="{[`month-picker--${variant}`]: true, 'year-picker': yearOnly}"
       class="sk-month-calendar__wrapper month-picker">
    <div v-if="showYear"
         class="sk-month-calendar__switcher-wrapper">
      <button type="button"
              class="sk-month-calendar__arrow-btn sk-month-calendar__arrow-btn--prev"
              @click="changeYear(-1)"></button>
      <p v-if="!editableYear"
         class="sk-month-calendar__month-text">{{ year }}</p>
      <input v-else
             v-model.number="year"
             type="text"
             @change="onChange()">
      <button type="button"
              class="sk-month-calendar__arrow-btn sk-month-calendar__arrow-btn--next"
              @click="changeYear(+1)"></button>
    </div>
    <div v-if="!yearOnly"
         class="month-picker__container">
      <div v-for="(month, monthIndex) in monthsByLang"
           :key="month"
           :class="{
             'inactive': isInactive(month),
             'clearable': clearable,
             'selected': !range && currentMonthIndex === monthIndex,
             'selected-range': range && monthIndex > firstRangeMonthIndex && monthIndex < secondRangeMonthIndex,
             'selected-range-first': range && firstRangeMonthIndex === monthIndex,
             'selected-range-second': range && secondRangeMonthIndex === monthIndex,
           }"
           class="month-picker__month"
           @click="selectMonth(monthIndex, true)">{{ month }}</div>
    </div>
  </div>
</template>

<script>
  import MonthPickerPrototype from './MonthPickerPrototype';

  export default {
    extends: MonthPickerPrototype,
    data() {
      return {
        currentMonthIndex: null,
        firstRangeMonthIndex: null,
        secondRangeMonthIndex: null,
        year: this.$moment().year()
      };
    },
    computed: {
      currentMonth() {
        if (this.currentMonthIndex !== null) {
          return this.monthsByLang[this.currentMonthIndex];
        }

        return null;
      },
      firstRangemonth() {
        if (this.firstRangeMonthIndex !== null) {
          return this.monthsByLang[this.firstRangeMonthIndex];
        }

        return null;
      },
      secondRangemonth() {
        if (this.secondRangeMonthIndex !== null) {
          return this.monthsByLang[this.secondRangeMonthIndex];
        }

        return null;
      },
      date() {
        const month = this.monthsByLang.indexOf(this.currentMonth) + 1;
        let dateFrom = this.$moment(`${this.year}/${month}/01`, 'YYYY/MM/DD').startOf('month');
        let dateTo = this.$moment(`${this.year}/${month}/01`, 'YYYY/MM/DD').endOf('month');

        const dateResult = {
          from: dateFrom,
          to: dateTo,
          month: this.monthsByLang[month - 1],
          monthIndex: month,
          year: this.year,
          rangeFrom: null,
          rangeTo: null,
          rangeFromMonth: null,
          rangeToMonth: null
        };

        if (this.range) {
          const monthRangeFrom = this.firstRangeMonthIndex + 1;
          const monthRangeTo = this.secondRangeMonthIndex + 1;

          dateFrom = new Date(`${this.year}/${monthRangeFrom}/01`);
          dateTo = new Date(`${this.year}/${monthRangeTo}/01`);

          dateResult.rangeFrom = this.firstRangeMonthIndex;
          dateResult.rangeTo = this.secondRangeMonthIndex;
          dateResult.rangeFromMonth = this.monthsByLang[this.firstRangeMonthIndex];
          dateResult.rangeToMonth = this.monthsByLang[this.secondRangeMonthIndex];
        }

        return dateResult;
      }
    },
    watch: {
      defaultMonth(newVal) {
        this.currentMonthIndex = newVal - 1;
      },
      defaultYear(newVal) {
        this.year = newVal;
      }
    },
    methods: {
      onChange() {
        if (!Number.parseInt(this.year)) {
          this.year = this.defaultYear || this.$moment().year();
        }

        this.$emit('change', this.date);
      },
      selectMonth(index, input = false) {
        if (this.isInactive(index)) {
          return;
        }

        if (this.range) {
          return this.selectMonthRange(index, input);
        }

        const isAlreadySelected = this.currentMonthIndex === index;
        if (this.clearable && isAlreadySelected) {
          this.currentMonthIndex = null;
          this.$emit('clear');
          return;
        }

        if (this.isAlreadySelected) {
          return;
        }

        this.currentMonthIndex = index;
        this.onChange();

        if (input) {
          this.$emit('input', this.date);
        }
      },
      selectMonthRange(index, input) {
        if (this.firstRangeMonthIndex === null) {
          this.firstRangeMonthIndex = index;
          return;
        }

        if (this.firstRangeMonthIndex !== null && this.secondRangeMonthIndex !== null) {
          this.firstRangeMonthIndex = index;
          this.secondRangeMonthIndex = null;
          return;
        }

        if (index >= this.firstRangeMonthIndex) {
          this.secondRangeMonthIndex = index;
          this.onChange();

          if (input) {
            this.$emit('input', this.date);
          }
          return;
        }

        this.firstRangeMonthIndex = index;
      },
      setDefaultMonthRange() {
        if (this.defaultMonthRange === null || this.defaultMonthRange.length !== 2) {
          return;
        }

        const [firstRange, secondRange] = this.defaultMonthRange;
        if (secondRange <= firstRange) {
          return;
        }

        this.firstRangeMonthIndex = firstRange;
        this.secondRangeMonthIndex = secondRange;
      },
      changeYear(value) {
        this.year += value;
        if (this.isInactive(0)) {
          return;
        }

        this.$emit('change-year', this.year);
      },
      isInactive(month) {
        let monthValue = month;
        if (this.minDate === null && this.maxDate === null) {
          return false;
        }

        if (Number.isInteger(monthValue)) {
          monthValue = this.monthsByLang[monthValue];
        }

        const monthKey = this.monthsByLang.indexOf(monthValue) + 1;
        const date = new Date(`${this.year}/${monthKey}/01`);
        const isValidDate = (date) => date !== null && (date instanceof Date);

        if (isValidDate(this.minDate) && date < this.minDate) {
          return true;
        }

        if (isValidDate(this.maxDate) && date > this.maxDate) {
          return true;
        }

        return false;
      }
    },
    mounted() {
      if (this.defaultYear) {
        this.year = this.defaultYear;
      }

      if (this.range) {
        this.setDefaultMonthRange();
        return;
      }

      if (this.defaultMonth) {
        this.selectMonth(this.defaultMonth - 1);
      } else if (!this.noDefault) {
        this.selectMonth(0);
      }
    }
  };
</script>

<style>
.month-picker__container {
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
}

.month-picker {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 9999;
  overflow: hidden;
  width: 300px;
  margin-top: 2px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}

.month-picker__month {
  flex-basis: 33.333%;
  padding: 10px;
  background-color: #fff;
  text-align: center;
  cursor: pointer;
}

.month-picker .month-picker__month:hover {
  background-color: #ccf6e4;
}

.month-picker__month.selected,
.month-picker__month.selected-range-first,
.month-picker__month.selected-range-second,
.month-picker__month.selected-range {
  background-color: #646d8c;
  background-color: var(--secondary-background-color);
  color: #fff;
  box-shadow: none;
}

.month-picker__month.selected-range-first,
.month-picker__month.selected-range-second {
  border-color: transparent;
}

.month-picker__month.inactive {
  color: #d5d5d5;
  box-shadow: none;
  text-shadow: none;
  cursor: not-allowed;
}

@media (max-width: 767px) {
  .month-picker__container {
    width: 100%;
  }
}
</style>
